@import "~antd/dist/antd.css";
@import "~antd/dist/antd.dark.css";
@import "~antd/dist/antd.compact.css";
.App {
	padding: 0;
	margin: 0;
}

svg {
	vertical-align: baseline;
}

.btn-orange {
	border-color: darkorange;
	background-color: orange !important;
}

.btn-orange:hover {
	border-color: darkorange;
	opacity: 80%;
	color: black !important;
}

.fade-in {
	animation: fadeIn 0.5s;
	-webkit-animation: fadeIn 0.5s;
	-moz-animation: fadeIn 0.5s;
	-o-animation: fadeIn 0.5s;
	-ms-animation: fadeIn 0.5s;
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@-moz-keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@-webkit-keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@-o-keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@-ms-keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.ant-table
	.ant-table-container
	.ant-table-content
	table
	thead.ant-table-thead
	.ant-table-cell {
	background-color: #0070a8;
	color: white;
	text-align: center;
	border-bottom: 2px solid #d7a600;
}

.center { 
    display: flex;
	justify-content: center;
	align-items: center;
} 
